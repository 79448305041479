import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'edit',
    name: 'settings.user.edit',
    component: () => import('@/views/control-panel/settings/users/user/Edit.vue'),
  },
]

export default routes
