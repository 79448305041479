import { CompanyState } from '@/types/store/Company'
import { ActionContext } from "vuex"

type Context = ActionContext<CompanyState, {}>

// initial state
const state = () => ({
  id: '4e0cbba3-bd7b-451b-9d20-89f01e68b3ff',
})

// getters
const getters = {
  id: (state: CompanyState) => state.id,
}

export default {
  namespaced: true,
  state,
  getters,
}
