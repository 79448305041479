import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'new',
    name: 'manuals.create',
    component: () => import('@/views/control-panel/manuals/Manual.vue'),
  },
  {
    path: ':manualID',
    name: 'manual.view',
    component: () => import('@/views/control-panel/manuals/Manual.vue'),
  },
  {
    path: ':manualID/new',
    name: 'manual.create',
    component: () => import('@/views/control-panel/manuals/Manual.vue'),
  },
]

export default routes
