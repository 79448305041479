import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'list',
    name: 'measure-values.list',
    component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/measure-location/measure-points/measure-point/measure-values/List.vue'),
  },
  {
    path: 'new',
    name: 'measure-values.create',
    component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/measure-location/measure-points/measure-point/measure-values/Create.vue'),
  },
  {
    path: ':measurementValuePartition/:measurementValueId',
    name: 'measure-value',
    component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/measure-location/measure-points/measure-point/measure-values/MeasureValue.vue'),
    // redirect: (to) => ({ name: 'control-panel.section.map', params: to.params }),
  }
]

export default routes
