import { RouteRecordRaw } from 'vue-router'
import CompaniesChildRoutes from '@/router/companies'
import RolesChildRoutes from '@/router/roles'
import UsersChildRoutes from '@/router/users'
import ParamAssessmentsChildRoutes from '@/router/param-assessments'
import { useStore } from 'vuex'
import ProjectsChildRoutes from '@/router/settingsprojects'
import ManualsChildRoutes from '@/router/manuals'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'param-assessments',
    name: 'settings.param-assessments',
    component: () => import('@/views/control-panel/settings/ParamAssessments.vue'),
    redirect: () => ({ name: 'param-assessments.list' }),
    children: ParamAssessmentsChildRoutes
  },
  {
    path: 'my-account',
    name: 'settings.my-account',
    component: () => import('@/views/control-panel/settings/MyAccount.vue'),
  },
  {
    path: 'companies',
    name: 'settings.companies',
    component: () => import('@/views/control-panel/settings/Companies.vue'),
    redirect: () => ({ name: 'settings.companies.list' }),
    children: CompaniesChildRoutes
  },
  {
    path: 'roles',
    name: 'settings.roles',
    component: () => import('@/views/control-panel/settings/Roles.vue'),
    redirect: () => ({ name: 'settings.roles.list' }),
    children: RolesChildRoutes
  },
  {
    path: 'users',
    name: 'settings.users',
    component: () => import('@/views/control-panel/settings/Users.vue'),
    redirect: () => ({ name: 'settings.users.list' }),
    children: UsersChildRoutes
  },
  {
    path: 'projects',
    name: 'settings.projects',
    component: () => import('@/views/control-panel/settings/Projects.vue'),
    redirect: () => ({ name: 'settings.projects.overview' }),
    children: ProjectsChildRoutes
  },
  {
    path: 'system',
    name: 'settings.system',
    component: () => import('@/views/control-panel/settings/System.vue'),
  }
]

export default routes
