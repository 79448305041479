import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'list',
    name: 'settings.roles.list',
    component: () => import('@/views/control-panel/settings/roles/List.vue'),
  },
  {
    path: 'create',
    name: 'settings.roles.create',
    component: () => import('@/views/control-panel/settings/roles/Create.vue'),
  },
  {
    path: ':role/edit',
    name: 'settings.roles.edit',
    component: () => import('@/views/control-panel/settings/roles/Edit.vue'),
  },
]

export default routes
