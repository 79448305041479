import { RouteRecordRaw } from 'vue-router'
import measurePointChildRoutes from '@/router/measure-point'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: 'kaart',
  //   name: 'map.locations',
  //   component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/Map.vue'),
  // },
  {
    path: 'list',
    name: 'measure-points.list',
    component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/measure-location/measure-points/List.vue'),
  },
  {
    path: 'new',
    name: 'measure-points.create',
    component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/measure-location/measure-points/Create.vue'),
  },
  {
    path: ':measurePoint',
    name: 'measure-point',
    component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/measure-location/measure-points/MeasurePoint.vue'),
    // redirect: (to) => ({ name: 'control-panel.section.map', params: to.params }),
    children: measurePointChildRoutes
  }
]

export default routes
