import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'overview',
    name: 'settings.projects.overview',
    component: () => import('@/views/control-panel/settings/projects/Overview.vue'),
  },
]

export default routes
