import { Interactabletutorial } from '@/types/Interactabletutorial'
import { InteractabletutorialState } from '@/types/store/Interactabletutorial'
import { ActionContext } from "vuex"

type Context = ActionContext<InteractabletutorialState, {}>

// initial state
const state = () => ({
  started: false,
})

// getters
const getters = {
  started: (state: InteractabletutorialState) => state.started
}

// actions
const actions = {
  start({ commit }: Context) {
    commit('start')
  },

  stop({ commit }: Context) {
    commit('stop')
  }
}

// mutations
const mutations = {
  start(state: InteractabletutorialState) {
    state.started = true
  },

  stop(state: InteractabletutorialState) {
    state.started = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
