import { ActionContext } from "vuex"
import { InteractableWindowState } from "@/types/store/InteractableWindow"
import { State } from "@/types/InteractableWindow"

type Context = ActionContext<InteractableWindowState, {}>

// initial state
const state = () => ({
  states: [] as State[]
})

// getters
const getters = {
  list: (storeState: InteractableWindowState) => storeState.states,
  find: (storeState: InteractableWindowState) => (id: string) => storeState.states.find(state => state.id === id)
}

// actions
const actions = {
  register({ commit }: Context, state: State) {
    if (state.id === '') {
      throw new Error('State ID must be set')
    }

    commit('add', state)
  },

  update({ commit }: Context, state: State) {
    commit('update', state)
  },

  unregister({ commit }: Context, id: string) {
    commit('remove', id)
  },

  clear({ commit }: Context) {
    commit('clear')
  },
}

// mutations
const mutations = {
  add(storeState: InteractableWindowState, state: State) {
    if (storeState.states.find(s => s.id === state.id)) {
      throw new Error(`State with ID ${state.id} already exists`)
    }

    storeState.states.push(state)
  },

  update(storeState: InteractableWindowState, state: State) {
    const index = storeState.states.findIndex(s => s.id === state.id)
    storeState.states[index] = state
  },

  remove(storeState: InteractableWindowState, id: string) {
    const index = storeState.states.findIndex(s => s.id === id)
    storeState.states.splice(index, 1)
  },

  clear(storeState: InteractableWindowState) {
    storeState.states = []
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
