import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'projects',
    name: 'map.projects',
    component: () => import('@/views/control-panel/projects/Map.vue'),
  },
  {
    path: 'projects/:project',
    component: () => import('@/views/control-panel/projects/Project.vue'),
    children: [
      {
        path: 'sections',
        name: 'map.sections',
        component: () => import('@/views/control-panel/projects/project/sections/Map.vue'),
      },
      {
        path: 'sections/:section',
        component: () => import('@/views/control-panel/projects/project/sections/Section.vue'),
        children: [
          {
            path: 'locations',
            name: 'map.locations',
            component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/Map.vue'),
          },
        ]
      }
    ]
  },
]

export default routes
