import { Notification } from '@/types/Notification'
import { NotificationState } from '@/types/store/Notification'
import { ActionContext } from "vuex"

type Context = ActionContext<NotificationState, {}>

// initial state
const state = () => ({
  all: [] as Notification[]
})

// getters
const getters = {
  all: (state: NotificationState) => state.all
}

// actions
const actions = {
  add({ commit }: Context, notification: Notification) {
    commit('add', notification)
    setTimeout(() => {
      commit('remove', notification)
    }, notification.timeout || 5000)
  },

  remove({ commit }: Context, notificationToRemove: Notification) {
    commit('remove', notificationToRemove)
  }
}

// mutations
const mutations = {
  add(state: NotificationState, notification: Notification) {
    state.all.push(notification)
  },

  remove(state: NotificationState, notificationToRemove: Notification) {
    // Find the index of the notification to remove
    const index = state.all.findIndex(notification => notification === notificationToRemove)

    // Remove the notification
    state.all.splice(index, 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
