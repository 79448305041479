import { Button } from './../../types/Dialog';
import { Dialog } from '@/types/Dialog'
import { DialogState } from '@/types/store/Dialog'
import { ActionContext } from "vuex"

type Context = ActionContext<DialogState, {}>

// initial state
const state = () => <DialogState>({
  show: false,
  showTypeIcon: false,
  type: 'info',
  title: '',
  message: '',
  buttons: []
})

// getters
const getters = {
  show(state: DialogState): boolean {
    return state.show
  },

  showTypeIcon(state: DialogState): boolean {
    return state.showTypeIcon ?? false
  },

  type(state: DialogState): string {
    return state.type
  },

  title(state: DialogState): string {
    return state.title
  },

  message(state: DialogState): string {
    return state.message ? state.message : ''
  },

  buttons(state: DialogState): Array<Button> {
    return state.buttons ? state.buttons : []
  }
}

// actions
const actions = {
  show({ commit }: Context, dialog: Dialog) {
    commit('show', dialog)
  },

  close({ commit }: Context) {
    commit('close')
  }
}

// mutations
const mutations = {
  show(state: DialogState, dialog: Dialog) {
    state.show = true
    state.showTypeIcon = dialog.showTypeIcon ?? false
    state.type = dialog.type ?? 'info'
    state.title = dialog.title ?? ''
    state.message = dialog.message ?? ''
    state.buttons = dialog.buttons ?? []
  },

  close(state: DialogState) {
    state.show = false
    setTimeout(() => {
      state.showTypeIcon = false
      state.type = 'info'
      state.title = ''
      state.message = ''
      state.buttons = []
    }, 250)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
