import { RouteRecordRaw } from 'vue-router'
import userChildRoutes from '@/router/user'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'list',
    name: 'settings.users.list',
    component: () => import('@/views/control-panel/settings/users/List.vue'),
  },
  {
    path: ':user',
    name: 'user',
    component: () => import('@/views/control-panel/settings/users/User.vue'),
    redirect: (to) => ({ name: 'settings.user.edit', params: to.params }),
    children: userChildRoutes
  },
  {
    path: 'create',
    name: 'settings.users.create',
    component: () => import('@/views/control-panel/settings/users/Create.vue'),
  }
]

export default routes
