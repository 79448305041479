import { createApp } from 'vue'
import '@/style.css'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import axios, { AxiosInstance } from 'axios'
import { createI18n } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'
import Highcharts from "highcharts"
import HighchartsVue from "highcharts-vue"
import stock from "highcharts/modules/stock"
import offlineExporting from "highcharts/modules/offline-exporting";
import exporting from "highcharts/modules/exporting"
import more from "highcharts/highcharts-more"
import config from '@/config/highcharts'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink, from } from '@apollo/client/core'
import { PublicClientApplication } from '@azure/msal-browser'
import * as Sentry from "@sentry/vue";
import '@/pwa.ts'

const msal = new PublicClientApplication({
  auth: {
    clientId: import.meta.env.VITE_MSAL_CLIENT_ID ? import.meta.env.VITE_MSAL_CLIENT_ID : '',
    authority: `https://login.microsoftonline.com/${import.meta.env.VITE_MSAL_AUTHORITY}`,
    redirectUri: import.meta.env.VITE_MSAL_REDIRECT,
    postLogoutRedirectUri: import.meta.env.VITE_MSAL_REDIRECT
  },
  cache: {
    cacheLocation: 'localStorage',
  }
})

const cache = new InMemoryCache()
const apolloHttpLink = createHttpLink({
  uri: import.meta.env.VITE_API_URL + '/graphql?code=' + import.meta.env.VITE_API_MASTER_KEY,
})
const apolloLink = new ApolloLink((operation, forward) => {
  const token = store.getters['auth/JWTToken']
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      'authorization': token ? `Bearer ${token}` : "",
      'Apollo-Require-Preflight': 'true'
    }
  }))
  return forward(operation)
})

const apolloClient = new ApolloClient({
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network',
      initialFetchPolicy: 'cache-and-network'
    },
  },
  link: from([
    apolloLink,
    apolloHttpLink
  ]),
})

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: import.meta.env.VITE_DEFAULT_LOCALE || 'en',
  fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE || 'en',
  availableLocales: ['nl', 'en'],
  messages: messages,
})

let missingMessages: string[] = []
i18n.global.setMissingHandler((locale, key, vm) => {
  if (!missingMessages.includes(key)) {
    missingMessages.push(key)
  }
  console.warn('Missing translation keys ', missingMessages)
})

const app = createApp(App)
if (process.env.NODE_ENV !== 'development') {
  const branchName: string | undefined = import.meta.env.VITE_BRANCH_NAME;
  const explodedBranchName: string[] = branchName?.split('/') ?? [];
  const appVersion: string = explodedBranchName.length > 0 ? explodedBranchName[explodedBranchName.length - 1] : 'test';
  const environment: string = appVersion === 'alpha' ? 'staging' : appVersion === 'test' ? 'test' : 'production';
  Sentry.init({
    app,
    dsn: "https://d62d59a700d85b4efb7947bc8038d7b0@o4505125815189504.ingest.us.sentry.io/4508336811016192",
    environment: environment,
    release: appVersion,
    integrations: [],
  });
}

const apiClient: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

if (import.meta.env.VITE_API_MASTER_KEY) {
  apiClient.defaults.params = {}
  apiClient.defaults.params.code = import.meta.env.VITE_API_MASTER_KEY
}

app.provide(DefaultApolloClient, apolloClient)
app.provide('msal', msal)
app.provide('axios', apiClient)
app.use(router)
app.use(store)
app.use(i18n)

// Highcharts
Highcharts.SVGRenderer.prototype.symbols.export = function (x, y, w, h) {
  const path = [
    // Arrow stem
    'M', x + w * 0.5, y,
    'L', x + w * 0.5, y + h * 0.7,
    // Arrow head
    'M', x + w * 0.3, y + h * 0.5,
    'L', x + w * 0.5, y + h * 0.7,
    'L', x + w * 0.7, y + h * 0.5,
    // Box
    'M', x, y + h * 0.9,
    'L', x, y + h,
    'L', x + w, y + h,
    'L', x + w, y + h * 0.9
  ];
  return path;
};
app.use(HighchartsVue)
stock(Highcharts)
exporting(Highcharts)
offlineExporting(Highcharts)
more(Highcharts)
Highcharts.setOptions(config)

app.mount('#app')
