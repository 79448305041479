<script setup lang="ts">
import { ref, Ref} from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  show: boolean
}>()

const { t } = useI18n()
const publicPath: Ref<string> = ref<string>(window.location.origin)
</script>
<template>
  <div :class="props.show ? 'block' : 'hidden'" class="pointer-events-none fixed inset-y-3 right-3 flex max-w-full pl-10 sm:pl-16 z-[53]">
    <div class="pointer-events-auto w-screen rounded-lg shadow-google overflow-hidden max-w-sm">
        <div id="projectsSlideover" class="flex flex-col h-full overflow-y-auto">
          <div class="bg-black/50 backdrop-blur-sm border-b p-4">
              <div class="flex items-center justify-between space-x-3">
                <div class="grow">
                    <h2 class="text-md font-medium text-white first-letter:capitalize">{{ t('All {resource}', { resource: t('project', 2) }) }}</h2>
                </div>
                <div class="flex h-7 items-center"><button type="button" class="rounded-full w-6 h-6 flex items-center justify-center border border-gray-300 transition-colors bg-gray-700 text-gray-300"><i class="far fa-times" aria-hidden="true"></i></button></div>
              </div>
          </div>
          <div class="relative flex-1 bg-white">
              <div class="flex flex-col h-full relative">
                <div class="sticky top-0 bg-white/50 backdrop-blur-lg z-30 border-b">
                    <form class="flex space-x-4" action="#">
                      <div class="flex-1 min-w-0">
                          <label for="search" class="sr-only">Zoeken</label>
                          <div class="relative">
                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"><i class="far fa-search text-gray-400" aria-hidden="true"></i></div>
                            <input readonly type="search" name="search" id="search" class="focus:shadow-none focus:ring-0 block w-full pl-10 py-3 border-none bg-transparent" :placeholder="t('Search by {argument}', { argument: `${t('project')} ${t('name')} ${t('or')} ${t('code')}...` })">
                          </div>
                      </div>
                    </form>
                </div>
                <nav class="overflow-y-auto flex-grow" aria-label="Directory" subtitleprop="code">
                    <div class="relative [&amp;:not(:first-child)]:border-t">
                      <div class="z-10 sticky top-0 border-b border-gray-200 bg-white px-4 py-1 text-sm font-medium text-gray-500">
                          <h3>G</h3>
                      </div>
                      <ul role="list" class="relative z-0 divide-y divide-gray-200">
                          <li id="9c4c9e64-9e3f-4b89-8556-0296e45d3049" class="hover:bg-gray-50 hover:border-l-4 hover:!border-l-red-500">
                            <a class="block cursor-pointer">
                                <div class="relative px-4 py-1.5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500">
                                  <div class="flex-1 min-w-0">
                                      <span class="absolute inset-0" aria-hidden="true"></span>
                                      <p class="text-xs font-medium text-gray-900">GWS project</p>
                                      <p class="text-xs text-gray-500 truncate">
                                      <ul class="flex flex-wrap items-center">
                                        <li class="relative">
                                            GWS<!--v-if-->
                                        </li>
                                        <!--v-if-->
                                      </ul>
                                      </p>
                                  </div>
                                </div>
                            </a>
                          </li>
                          <li id="74c46f48-978d-43e5-a0f1-952e924e353b" class="hover:bg-gray-50 hover:border-l-4 hover:!border-l-red-500">
                            <a class="block cursor-pointer">
                                <div class="relative px-4 py-1.5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500">
                                  <div class="flex-1 min-w-0">
                                      <span class="absolute inset-0" aria-hidden="true"></span>
                                      <p class="text-xs font-medium text-gray-900">Geonius project 1</p>
                                      <p class="text-xs text-gray-500 truncate">
                                      <ul class="flex flex-wrap items-center">
                                        <li class="relative">
                                            G714785
                                        </li>
                                      </ul>
                                      </p>
                                  </div>
                                </div>
                            </a>
                          </li>
                      </ul>
                    </div>
                    <div class="relative [&amp;:not(:first-child)]:border-t">
                      <div class="z-10 sticky top-0 border-b border-gray-200 bg-white px-4 py-1 text-sm font-medium text-gray-500">
                          <h3>Z</h3>
                      </div>
                      <ul role="list" class="relative z-0 divide-y divide-gray-200">
                          <li id="3d9deff0-446c-4f61-b81c-297dd3d0e796" class="hover:bg-gray-50 hover:border-l-4 hover:!border-l-red-500">
                            <a class="block cursor-pointer">
                                <div class="relative px-4 py-1.5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500">
                                  <div class="flex-1 min-w-0">
                                      <span class="absolute inset-0" aria-hidden="true"></span>
                                      <p class="text-xs font-medium text-gray-900">ZX - Project</p>
                                      <p class="text-xs text-gray-500 truncate">
                                      <ul class="flex flex-wrap items-center">
                                        <li class="relative">
                                            B18416
                                        </li>
                                      </ul>
                                      </p>
                                  </div>
                                </div>
                            </a>
                          </li>
                      </ul>
                    </div>
                </nav>
                <div class="py-5 px-6 border-t bg-gray-50"><span class="text-blue-600 hover:underline focus:underline">{{ t('See detailed list')}}<i class="far fa-arrow-right" aria-hidden="true"></i></span></div>
              </div>
          </div>
        </div>
    </div>
  </div>
</template>
