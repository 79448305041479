import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'sign-in',
    name: 'auth.login',
    component: () => import('@/views/auth/Login.vue')
  },
  {
    path: 'sign-out',
    name: 'auth.signOut',
    component: () => import('@/views/auth/SignOut.vue')
  }
]

export default routes
