<script setup lang="ts">
import { ref, Ref} from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  show: boolean
}>()

const { t } = useI18n()
const publicPath: Ref<string> = ref<string>(window.location.origin)
</script>
<template>
<div :class="props.show ? 'block' : 'hidden'" class="pointer-events-none fixed inset-y-3 right-3 flex max-w-full pl-10 sm:pl-16 z-[53]">
   <div class="pointer-events-auto w-screen rounded-lg shadow-google overflow-hidden max-w-md">
      <div id="sectionsSlideover" class="flex flex-col h-full overflow-y-auto" style="">
         <div class="bg-black/50 backdrop-blur-sm border-b p-4">
            <div class="flex items-center justify-between space-x-3">
               <!--v-if-->
               <div class="grow">
                <h2 class="text-md font-medium text-white first-letter:capitalize">{{ t('All {resource}', { resource: t('section', 2) }) }}</h2>
                  <!--v-if-->
               </div>
               <div class="flex h-7 items-center"><button type="button" class="rounded-full w-6 h-6 flex items-center justify-center border border-gray-300 transition-colors bg-gray-700 text-gray-300"><i class="far fa-times" aria-hidden="true"></i></button></div>
            </div>
            <!--v-if-->
         </div>
         <div class="relative flex-1 bg-white">
            <div class="flex flex-col h-full">
               <div class="p-6">
                  <form class="flex space-x-4" action="#">
                     <div class="flex-1 min-w-0">
                        <label for="search" class="sr-only">Zoeken</label>
                        <div class="relative rounded-md shadow-sm">
                           <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"><i class="far fa-search text-gray-400" aria-hidden="true"></i></div>
                           <input type="search" name="search" id="search" class="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" :placeholder="t('Search by {argument}', { argument: `${t('section')} ${t('name')}...` })">
                        </div>
                     </div>
                  </form>
               </div>
               <nav class="overflow-y-auto flex-grow" aria-label="Directory" subtitleprop="code">
                  <div class="relative [&amp;:not(:first-child)]:border-t">
                     <div class="z-10 sticky top-0 border-b border-gray-200 bg-white px-4 py-1 text-sm font-medium text-gray-500">
                        <h3>F</h3>
                     </div>
                     <ul role="list" class="relative z-0 divide-y divide-gray-200">
                        <li id="acb46bcc-65ce-4dce-9aaa-4f5431152e5d" class="hover:bg-gray-50 hover:border-l-4 hover:!border-l-red-500">
                           <a class="block cursor-pointer">
                              <div class="relative px-4 py-1.5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500">
                                 <div class="flex-1 min-w-0">
                                    <span class="absolute inset-0" aria-hidden="true"></span>
                                    <p class="text-xs font-medium text-gray-900">Fase 1</p>
                                    <!--v-if-->
                                 </div>
                              </div>
                           </a>
                        </li>
                        <li id="21485078-bb49-4e00-9451-89a269271a96" class="hover:bg-gray-50 hover:border-l-4 hover:!border-l-red-500">
                           <a class="block cursor-pointer">
                              <div class="relative px-4 py-1.5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500">
                                 <div class="flex-1 min-w-0">
                                    <span class="absolute inset-0" aria-hidden="true"></span>
                                    <p class="text-xs font-medium text-gray-900">Fase 2</p>
                                    <!--v-if-->
                                 </div>
                              </div>
                           </a>
                        </li>
                     </ul>
                  </div>
                  <div class="relative [&amp;:not(:first-child)]:border-t">
                     <div class="z-10 sticky top-0 border-b border-gray-200 bg-white px-4 py-1 text-sm font-medium text-gray-500">
                        <h3>T</h3>
                     </div>
                     <ul role="list" class="relative z-0 divide-y divide-gray-200">
                        <li id="84017d4c-1ab0-4b70-95f4-bc0f3181d601" class="hover:bg-gray-50 hover:border-l-4 hover:!border-l-red-500">
                           <a class="block cursor-pointer">
                              <div class="relative px-4 py-1.5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500">
                                 <div class="flex-1 min-w-0">
                                    <span class="absolute inset-0" aria-hidden="true"></span>
                                    <p class="text-xs font-medium text-gray-900">test rob</p>
                                    <!--v-if-->
                                 </div>
                              </div>
                           </a>
                        </li>
                     </ul>
                  </div>
               </nav>
               <div class="py-5 px-6 border-t bg-gray-50"><span class="text-blue-600 hover:underline focus:underline">{{ t('See detailed list')}}<i class="far fa-arrow-right" aria-hidden="true"></i></span></div>
            </div>
         </div>
      </div>
   </div>
</div>
</template>
