import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'edit',
    name: 'settings.company.edit',
    component: () => import('@/views/control-panel/settings/companies/company/Edit.vue'),
  },
  {
    path: 'users',
    name: 'settings.company.users',
    component: () => import('@/views/control-panel/settings/companies/company/UsersList.vue'),
  },
]

export default routes
