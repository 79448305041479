<script setup lang="ts">
import { ref, Ref} from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  show: boolean
}>()

const { t } = useI18n()
const publicPath: Ref<string> = ref<string>(window.location.origin)

const legendaItems = ref([
  {
    name: 'Inclino',
    icon: 'inclino.svg',
    show: true,
    present: true
  },
  {
    name: 'Monitoring well',
    icon: 'monitoring_well.svg',
    show: false,
    present: true
  },
  {
    name: 'Displacement',
    icon: 'displacement.svg',
    show: true,
    present: true
  }
])

const mapDataItems = ref([
  {
    name: 'Kadastrale kaart',
    icon: 'kadaster-layer-icon.png'
  },
  {
    name: 'Gemeenten',
    icon: 'community-layer-icon.png'
  },
  {
    name: 'Provincies',
    icon: 'province-layer-icon.png'
  },
  {
    name: 'AHN4',
    icon: 'ahn-dtm-layer-icon.png'
  }
])
</script>

<template>
  <div :class="props.show ? 'block' : 'hidden'" class="absolute left-20 bottom-3 space-y-3 z-30">
   <div class="flex gap-1 items-end">
      <div id="layersmenu" class="backdrop-blur-sm bg-white rounded-lg shadow-google p-1">
         <div class="flex gap-3">
            <div class="space-y-3 select-none" style="">
               <div data-headlessui-state="open" class="grid grid-cols-1">
                  <button  type="button" aria-expanded="true" aria-controls="headlessui-disclosure-panel-v-1" data-headlessui-state="open" class="text-sm text-gray-900 font-medium bg-gray-100 rounded-lg py-1 px-2 flex justify-between w-full items-center min-w-48">
                     <p v-t="'Legend'">Legenda</p>
                     <i class="fas fa-chevron-down text-gray-500" aria-hidden="true" style="display: none;"></i><i class="fas fa-chevron-up text-gray-500" aria-hidden="true"></i>
                  </button>
                  <div class="divide-y divide-gray-200">
                    <div v-for="item in legendaItems" :key="item.name" class="cursor-pointer py-2 last-of-type:pb-0 space-y-1" :class="!item.show ? 'opacity-30' : ''">
                      <div class="flex items-center text-xs ml-1 select-none w-full"><img :src="`${publicPath}/icons/sensors/${item.icon}`" class="w-7 h-7"><span class="ml-2">{{ t(`MeasurePointTypes.${item.name}`) }}</span><i v-if="!item.show" class="fa-solid fa-eye-slash text-sm absolute right-[8px]" aria-hidden="true"></i><i v-else class="fa-solid fa-eye text-sm absolute right-[9px]" aria-hidden="true"></i></div>
                    </div>
                  </div>
               </div>
               <div data-headlessui-state="open" class="space-y-1">
                  <button  type="button" aria-expanded="true" aria-controls="headlessui-disclosure-panel-v-3" data-headlessui-state="open" class="text-sm text-gray-900 font-medium bg-gray-100 rounded-lg py-1 px-2 flex justify-between w-full items-center min-w-48">
                     <p v-t="'Map data'">Map data</p>
                     <i class="fas fa-chevron-down text-gray-500" aria-hidden="true" style="display: none;"></i><i class="fas fa-chevron-up text-gray-500" aria-hidden="true"></i>
                  </button>
                  <div data-headlessui-state="open" class="grid grid-cols-2 gap-1">
                    <div v-for="layer in mapDataItems" class="w-24 h-16 relative rounded-lg bg-gray-100 cursor-pointer">
                      <div class="absolute inset-0 rounded-lg bg-no-repeat bg-center bg-cover" :style="{ backgroundImage: `url(${publicPath}/img/${layer.icon})` }"></div>
                      <div class="absolute inset-0 bg-gradient-to-t from-black/60 to-black/0 rounded-lg tracking-tight"></div>
                      <p class="absolute px-1.5 pb-1.5 bottom-0 text-[11px] leading-none text-white font-semibold antialiased">{{ layer.name }}</p>
                    </div>
                  </div>

              <template>
                <div v-for="item in mapDataItems" :key="item.name" class="w-24 h-16 relative rounded-lg bg-gray-100 cursor-pointer">
                  <div class="absolute inset-0 rounded-lg bg-no-repeat bg-center bg-cover" :style="{ backgroundImage: `url(${publicPath}/img/${item.icon})` }"></div>
                  <div class="absolute inset-0 bg-gradient-to-t from-black/60 to-black/0 rounded-lg tracking-tight"></div>
                  <div class="absolute inset-0 rounded-lg border-2 ring-2 ring-inset ring-white ring-offset-0" style="display: none;"></div>
                  <p class="absolute px-1.5 pb-1.5 bottom-0 text-[11px] leading-none text-white font-semibold antialiased">{{ item.name }}</p>
                </div>
              </template>
               </div>
               <div data-headlessui-state="open" class="space-y-1">
                  <button  type="button" aria-expanded="true" aria-controls="headlessui-disclosure-panel-v-5" data-headlessui-state="open" class="text-sm text-gray-900 font-medium bg-gray-100 rounded-lg py-1 px-2 flex justify-between w-full items-center min-w-48">
                     <p>Kaarttypes</p>
                     <i class="fas fa-chevron-down text-gray-500" aria-hidden="true" style="display: none;"></i><i class="fas fa-chevron-up text-gray-500" aria-hidden="true"></i>
                  </button>
                  <div  data-headlessui-state="open" class="grid grid-cols-2 gap-1">
                     <div class="w-24 h-16 relative rounded-lg bg-gray-100 cursor-pointer">
                        <div class="absolute inset-0 rounded-lg bg-no-repeat bg-center bg-cover" :style="{ backgroundImage: `url(${publicPath}/img/osm-layer-icon.png)` }"></div>
                        <div class="absolute inset-0 bg-gradient-to-t from-black/60 to-black/0 rounded-lg tracking-tight"></div>
                        <div class="absolute inset-0 rounded-lg border-2 ring-2 ring-inset ring-white ring-offset-0 border-blue-500"></div>
                        <p class="absolute px-1.5 pb-1.5 bottom-0 text-[11px] leading-none text-white font-semibold antialiased">OSM</p>
                     </div>
                     <div class="w-24 h-16 relative rounded-lg bg-gray-100 cursor-pointer">
                        <div class="absolute inset-0 rounded-lg bg-no-repeat bg-center bg-cover" :style="{ backgroundImage: `url(${publicPath}/img/pdok-layer-icon.jpeg)` }"></div>
                        <div class="absolute inset-0 bg-gradient-to-t from-black/60 to-black/0 rounded-lg tracking-tight"></div>
                        <div class="absolute inset-0 rounded-lg border-2 ring-2 ring-inset ring-white ring-offset-0 border-blue-500" style="display: none;"></div>
                        <p class="absolute px-1.5 pb-1.5 bottom-0 text-[11px] leading-none text-white font-semibold antialiased">PDOK Luchtfoto</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</template>
