import { RouteRecordRaw } from 'vue-router'
import sectionChildRoutes from '@/router/section'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'list',
    name: 'control-panel.sections.list',
    component: () => import('@/views/control-panel/projects/project/sections/List.vue'),
  },
  {
    path: 'new',
    name: 'control-panel.sections.create',
    component: () => import('@/views/control-panel/projects/project/sections/Create.vue'),
  },
  {
    path: ':section',
    name: 'control-panel.section',
    component: () => import('@/views/control-panel/projects/project/sections/Section.vue'),
    redirect: (to) => ({ name: 'control-panel.section.map', params: to.params }),
    children: sectionChildRoutes
  }
]

export default routes
