import { RouteRecordRaw } from 'vue-router'
import MapRoutes from '@/router/map'
import ProjectRoutes from '@/router/projects'
import SensorRoutes from '@/router/sensors'
import SettingRoutes from '@/router/settings'
import DashboardRoutes from '@/router/dashboards'
import ManualRoutes from '@/router/manualsuser'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'projects',
    name: 'control-panel.projects',
    component: () => import('@/views/control-panel/Projects.vue'),
    meta: { title: 'Alle projecten' },
    children: ProjectRoutes,
    redirect: () => ({ name: 'control-panel.projects.list' })
  },
  {
    path: 'map',
    name: 'control-panel.map',
    component: () => import('@/views/control-panel/Projects.vue'),
    children: MapRoutes,
    redirect: () => ({ name: 'map.projects' }),
  },
  {
    path: 'sensors',
    name: 'sensors',
    component: () => import('@/views/control-panel/Sensors.vue'),
    redirect: () => ({ name: 'sensors.list' }),
    children: SensorRoutes
  },
  {
    path: 'dashboards',
    name: 'dashboards',
    component: () => import('@/views/control-panel/Dashboards.vue'),
    redirect: () => ({ name: 'dashboard.charts' }),
    children: DashboardRoutes
  },
  {
    path: 'settings',
    name: 'settings',
    component: () => import('@/views/control-panel/Settings.vue'),
    meta: { title: 'Instellingen' },
    redirect: () => ({ name: 'param-assessments.list' }),
    children: SettingRoutes
  },
  {
    path: 'manuals',
    name: 'manuals',
    component: () => import('@/views/control-panel/Manuals.vue'),
    children: ManualRoutes
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'control-panel.not-found',
    component: () => import('@/views/control-panel/NotFound.vue'),
  },
]

export default routes
