import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'charts',
    name: 'dashboard.charts',
    component: () => import('@/views/control-panel/dashboards/Charts.vue'),
    redirect: (to) => ({ name: 'charts.view', params: { view: 'default' }, query: to.query}),
    children: [
      {
        path: ':view',
        name: 'charts.view',
        component: () => import('@/views/control-panel/dashboards/PivotGrid.vue'),
      },
    ]
  },
  {
    path: 'pivot-grid',
    name: 'dashboard.pivot-grid',
    component: () => import('@/views/control-panel/dashboards/PivotGrid.vue'),
    redirect: (to) => ({ name: 'pivot-grid.view', params: { view: 'default' }, query: to.query}),
    children: [
      {
        path: ':view',
        name: 'pivot-grid.view',
        component: () => import('@/views/control-panel/dashboards/PivotGrid.vue'),
      },
    ]
  },
]

export default routes
