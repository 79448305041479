<script setup lang="ts">
import { defineProps, defineEmits, toRefs } from 'vue'

const props = defineProps<{
  href: string
  icon: string
  label: string
  isActive: boolean
  textHidden: boolean
}>()

const emits = defineEmits<{
  (event: 'navigate', ...args: any[]): void
}>()

const { href, icon, label, isActive } = toRefs(props)
</script>

<template>
  <a
    :href="href"
    @click.prevent="emits('navigate')"
    :class="[
      isActive ? 'bg-gray-100 text-gray-800' : 'text-gray-800 hover:bg-gray-100 border-white hover:border-gray-200 hover:text-gray-800',
      'flex items-center justify-start gap-x-2 px-2 py-2 text-sm rounded-md align-middle border',
    ]">
    <i
    aria-hidden="true"
    :class="[
      icon,
      'flex-shrink-0 text-lg w-6 align-middle text-center',
    ]" />
    <p :class="[
      'break-words first-letter:capitalize align-middle transition-opacity ease-in-out',
      { 'opacity-0': textHidden },
      { 'opacity-100': !textHidden }
    ]">{{ label }}</p>
  </a>
</template>
