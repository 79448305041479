import { RouteRecordRaw } from 'vue-router'
import companyChildRoutes from '@/router/company'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'list',
    name: 'settings.companies.list',
    component: () => import('@/views/control-panel/settings/companies/List.vue'),
  },
  {
    path: 'new',
    name: 'settings.companies.create',
    component: () => import('@/views/control-panel/settings/companies/Create.vue'),
  },
  {
    path: ':company',
    name: 'company',
    component: () => import('@/views/control-panel/settings/companies/Company.vue'),
    redirect: (to) => ({ name: 'settings.company.edit', params: to.params }),
    children: companyChildRoutes
  }
]

export default routes
