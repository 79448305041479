import { RouteRecordRaw } from 'vue-router'
import projectChildRoutes from '@/router/project'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'list',
    name: 'control-panel.projects.list',
    component: () => import('@/views/control-panel/projects/List.vue'),
  },
  {
    path: 'new',
    name: 'control-panel.projects.create',
    component: () => import('@/views/control-panel/projects/Create.vue'),
  },
  {
    path: ':project',
    name: 'control-panel.project',
    component: () => import('@/views/control-panel/projects/Project.vue'),
    // redirect: (to) => ({ name: 'control-panel.project.map', params: to.params }),
    children: projectChildRoutes
  }
]

export default routes
