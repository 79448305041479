import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'list',
    name: 'param-assessments.list',
    component: () => import('@/views/control-panel/settings/paramAssessments/List.vue'),
  }
]

export default routes
