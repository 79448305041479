import { EnumsState } from '@/types/store/Enums'
import { ActionContext } from "vuex"
import axios, { AxiosInstance } from 'axios'
import { Enum, EnumResource } from '@/types/Enum'

const api: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
if (import.meta.env.VITE_API_MASTER_KEY) {
  api.defaults.params = {}
  api.defaults.params.code = import.meta.env.VITE_API_MASTER_KEY
}

type Context = ActionContext<EnumsState, {}>

// initial state
const state = () => <EnumsState>({
  all: [],
})

// getters
const getters = {
  all: (state: EnumsState): Enum[] => state.all,
  findById: (state: EnumsState) => (id: Enum['id'], type?: Enum['type']): Enum | undefined => {
    if (type) return state.all.find((item: Enum) => item.id === id && item.type === type)
    return state.all.find((item: Enum) => item.id === id)
  },
  findByType: (state: EnumsState) => (type: Enum['type']): Enum[] => state.all.filter((item: Enum) => item.type === type),
}

// actions
const actions = {
  async fetchAll(context: Context): Promise<void> {
    try {
      const { data } = await api.get(`enums`)
      context.commit('set', data.data)
    } catch (error: any) {
      console.error(error)
    }
  }
}

// mutations
const mutations = {
  set(state: EnumsState, enums: EnumResource[]): void {
    state.all = enums.map((item: EnumResource) => {
      // Remove Cosmos DB metadata
      const {
        _attachments, _etag, _rid, _self, _ts,
        ...rest
      } = item
      return rest
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
