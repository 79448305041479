import { createI18n } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: import.meta.env.VITE_DEFAULT_LOCALE || 'en',
  fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE || 'en',
  availableLocales: ['nl', 'en'],
  messages: messages,
})

const t = i18n.global.t

export default {
  credits: {
    enabled: false
  },
  time: {
    timezone: 'Europe/Amsterdam'
  },
  lang: {
    resetZoom: t('Reset zoom'),
    downloadCSV: t('Download {resource}', { resource: 'CSV' }),
    downloadJPEG: t('Download {resource}', { resource: 'JPEG' }),
    downloadMIDI: t('Download {resource}', { resource: 'MIDI' }),
    downloadPDF: t('Download {resource}', { resource: 'PDF' }),
    downloadPNG: t('Download {resource}', { resource: 'PNG' }),
    downloadSVG: t('Download {resource}', { resource: 'SVG' }),
    downloadXLS: t('Download {resource}', { resource: 'XLS' }),
    months: [
      t('January'),
      t('February'),
      t('March'),
      t('April'),
      t('May_Long'),
      t('June'),
      t('July'),
      t('August'),
      t('September'),
      t('October'),
      t('November'),
      t('December')
    ],
    shortMonths: [
      t('Jan'),
      t('Feb'),
      t('Mar'),
      t('Apr'),
      t('May'),
      t('Jun'),
      t('Jul'),
      t('Aug'),
      t('Sep'),
      t('Oct'),
      t('Nov'),
      t('Dec')
    ],
    weekdays: [
      t('Sunday'),
      t('Monday'),
      t('Tuesday'),
      t('Wednesday'),
      t('Thursday'),
      t('Friday'),
      t('Saturday')
    ],
    viewFullscreen: t('View in full screen'),
    exitFullscreen: t('Exit from full screen'),
    printChart: t('Print chart'),
    loading: t('Loading') + '...',
  },
}
