import { Company } from '@/types/Company'
import { UserSettings } from '@/types/UserSettings'
import { UserState } from '@/types/store/User'
import { ActionContext } from "vuex"

type Context = ActionContext<UserState, {}>

// initial state
const state = () => ({
  id: '',
  name: '',
  username: '',
  companyAdmin: '',
  applicationadmin: false,
  roles: [],
  projectAccessIds: [],
  settings: {} as UserSettings
})

// getters
const getters = {
  id: (state: UserState) => state.id,
  name: (state: UserState) => state.name,
  username: (state: UserState) => state.username,
  settings: (state: UserState) => state.settings,
  belongsToCompany: (state: UserState) => state.belongsToCompany,
  isApplicationAdmin: (state: UserState) => state.applicationadmin,
  isCompanyAdmin: (state: UserState) => state.companyAdmin,
  projectAccessIds: (state: UserState) => state.projectAccessIds,
  roles: (state: UserState) => {
    return state.roles
  },
  hasRoles: (state: UserState) => (roles: string[]) => {
    return roles.some((role: string) => state.roles.includes(role))
  }
}

// actions
const actions = {
  setSettings(context: Context, newUserSettings: UserSettings): void {
    context.commit('setSettings', newUserSettings)
  },
}

// mutations
const mutations = {
  setId(state: UserState, newId: string) {
    state.id = newId
  },

  setSettings(state: UserState, newUserSettings: UserSettings) {
    state.settings = newUserSettings
  },

  setName(state: UserState, newName: string) {
    state.name = newName
  },

  setUsername(state: UserState, newUsername: string) {
    state.username = newUsername
  },

  setBelongsToCompany(state: UserState, newCompany: string) {
    state.belongsToCompany = newCompany
  },

  setCompanyAdmin(state: UserState, newCompanyAdmin: boolean) {
    state.companyAdmin = newCompanyAdmin
  },

  setApplicationAdmin(state: UserState, newApplicationAdmin: boolean) {
    state.applicationadmin = newApplicationAdmin
  },

  setProjectAccessIds(state: UserState, newProjectAccessIds: string[]) {
    state.projectAccessIds = newProjectAccessIds
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
