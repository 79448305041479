import { RouteRecordRaw } from 'vue-router'
import measureLocationChildRoutes from '@/router/measure-location'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'list',
    name: 'measure-locations.list',
    component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/List.vue'),
  },
  {
    path: 'round',
    name: 'measure-locations.round',
    component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/MeasureRound.vue'),
  },
  {
    path: 'new',
    name: 'measure-locations.create',
    component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/Create.vue'),
  },
  {
    path: ':measureLocation',
    name: 'measure-location',
    component: () => import('@/views/control-panel/projects/project/sections/section/measure-locations/MeasureLocation.vue'),
    // redirect: (to) => ({ name: 'control-panel.section.map', params: to.params }),
    children: measureLocationChildRoutes
  }
]

export default routes
